body {
  margin: 0;
  font-family: futura-pt, sans-serif;
  font-style: normal;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', */
  /*   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', */
  /*   sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  background-color: #fff !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* react leaflet */
.leaflet-container {
  height: 100%;
  width: 100%;
  position: relative;
}

.leaflet-control-attribution {
  display: none;
}

/* react-leaflet-draw */
.sr-only {
  display: none;
}

/**
 * PDF Hacks
 *
 * It would be nice to get a copy of the PDF which does not have these issues.
 * This will have to suffice for the moment.
 */
.react-pdf__Document rect {
  fill: #fff;
}
.react-pdf__Document [data-page-number="1"] > .react-pdf__Page__textContent > span {
  color: #1a428a;
}
.react-pdf__Document [data-page-number="1"] > .react-pdf__Page__textContent > span:last-of-type {
  display: none;
  visibility: hidden;
}
.react-pdf__Document [data-page-number="1"] > .react-pdf__Page__svg [clip-path="url(#clippath2)"],
.react-pdf__Document [data-page-number="1"] > .react-pdf__Page__svg [clip-path="url(#clippath4)"],
.react-pdf__Document [data-page-number="1"] > .react-pdf__Page__svg [clip-path="url(#clippath164)"] {
  display: none;
  visibility: hidden;
}
.react-pdf__Document [data-page-number="1"] > .react-pdf__Page__svg [clip-path="url(#clippath165)"] path {
  fill: #fff;
}
